import { Injectable} from '@angular/core';
import { IResponse } from '../interfaces';
import { AllCryptoUserModel, CryptoUserFilterModel, CryptoUserModel,
  IncomeStatModel, InsurancePayRequestFilterModel, InsurancePayRequestModel, PersonalDataModel,
  PersonalDataRequestModel, ReferralStatModel, WithdrawInfoModel, PartnerBlockModel, PaginationDataModel } from '../model';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { CodeType } from '../enums';
import { UsersKYCStatsModel } from '../model/usersKYCStats.model';
import { EventService } from './event.service';

const cryptoUserApiUrl = 'api/cryptoUser';

@Injectable({
  providedIn: 'root'
})
export class CryptoUserService {
  chatEventListenerAdded: boolean = false;
  public cryptoUser: CryptoUserModel;
  public type2FA: number = 0;
  public email: string = "";
  credentials$:any;

  constructor(
    private api: ApiService,
    private eventService: EventService
  ) {
  }

  CredentialSetted(credentials: {email: string, password: string}, type2FA: number, email?: string){
    var t  = this;
    var credentialsSubject = new BehaviorSubject<{email: string, password: string}>(credentials);
    t.credentials$ = credentialsSubject.asObservable();
    t.type2FA = type2FA;
    t.email = credentials.email;
  }

  CleanCredentials(){
    var t = this;
    t.credentials$ = {};
    t.type2FA = 0;
  }

  public clear() {
    this.cryptoUser = undefined;
  }

  public init(): Promise<CryptoUserModel> {
    var promise: Promise<CryptoUserModel>;
    if (this.get() == undefined || this.get() == null) {
      promise = this.refresh();
    } else {
      promise = new Promise<CryptoUserModel>((resolve, reject) => {
        resolve(this.get())
      });
    }

    return promise;
  }

  public refresh = (): Promise<CryptoUserModel> => {
    var t = this;
    return this.api.get<CryptoUserModel>(cryptoUserApiUrl).toPromise()
      .then(resp => {
        t.cryptoUser = resp.data;
        if (t.cryptoUser.deleted) {
          t.eventService.NeedSignOutUser.emit();
        }
        return t.cryptoUser;
      });
  }

  public get(): CryptoUserModel {
    var t = this;
    return t.cryptoUser;
  }


  public getUserForAdminById(id: number): Promise<IResponse<CryptoUserModel>> {
    return this.api.get<CryptoUserModel>(cryptoUserApiUrl + '/getUserForAdmin/' + id).toPromise();
  }

  public getUserBranch(id: number): Promise<IResponse<CryptoUserModel[]>> {
    return this.api.get<CryptoUserModel[]>(cryptoUserApiUrl + '/getUserBranch/' + id).toPromise();
  }

  public getIncomeStatById(id: number): Promise<IResponse<IncomeStatModel[]>> {
    return this.api.get<IncomeStatModel[]>(cryptoUserApiUrl + '/incomestatstable/' + id).toPromise();
  }

  public getReferralStatById(id: number): Promise<IResponse<ReferralStatModel>> {
    return this.api.get<ReferralStatModel>(cryptoUserApiUrl + '/referralstats/' + id).toPromise();
  }

  public delUser(id: number): Promise<IResponse<AllCryptoUserModel>> {
    return this.api.get<AllCryptoUserModel>(cryptoUserApiUrl + '/deluser/' + id).toPromise();
  }

  public delUserAccount(): Promise<IResponse<AllCryptoUserModel>> {
    return this.api.get<AllCryptoUserModel>(cryptoUserApiUrl + '/deluseraccount').toPromise();
  }

  public UndelUser(id: number): Promise<IResponse<AllCryptoUserModel>> {
    return this.api.get<AllCryptoUserModel>(cryptoUserApiUrl + '/undeluser/' + id).toPromise();
  }

  public changeStatus(userRefStatusId: number, userId: number): Promise<IResponse<AllCryptoUserModel>> {
    return this.api.get<AllCryptoUserModel>(cryptoUserApiUrl + '/changerole/' + userRefStatusId + '/' + userId).toPromise();
  }
  
  public getAllUsers(filter: CryptoUserFilterModel): Promise<IResponse<PaginationDataModel<AllCryptoUserModel[]>>> {
    return this.api.post<PaginationDataModel<AllCryptoUserModel[]>>(cryptoUserApiUrl + '/getallusers/', filter).toPromise();
  }

  public getAllReferrals(filter: CryptoUserFilterModel): Promise<IResponse<PaginationDataModel<AllCryptoUserModel[]>>> {
    return this.api.post<PaginationDataModel<AllCryptoUserModel[]>>(cryptoUserApiUrl + '/getAllReferrals/', filter).toPromise();
  }

  public getAllInsurancePayRequests(filter: InsurancePayRequestFilterModel): Promise<IResponse<InsurancePayRequestModel[]>> {
    return this.api.post<InsurancePayRequestModel[]>(cryptoUserApiUrl + '/getAllInsrRequests', filter).toPromise();
  }

  public getUserVerificationRequests(id: number): Promise<IResponse<PersonalDataRequestModel[]>> {
    return this.api.get<PersonalDataRequestModel[]>(cryptoUserApiUrl + '/getPersDataRequest/' + id).toPromise();
  }

  public getPersonalData(id: number, verificationId: number): Promise<IResponse<PersonalDataModel>> {
    return this.api.get<PersonalDataModel>(cryptoUserApiUrl + '/getpersdata/' + id + '/' + verificationId).toPromise();
  }

  public getUserPayRequests(id: number): Promise<IResponse<InsurancePayRequestModel[]>> {
    return this.api.get<InsurancePayRequestModel[]>(cryptoUserApiUrl + "/getUserInsrRequests/" + id).toPromise();
  }

  public setVerification(id: number, verifStatus: number): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(cryptoUserApiUrl + '/setverification/' + id + '/' + verifStatus).toPromise();
  }

  public reset2fa(id: number): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(cryptoUserApiUrl + '/resettwofactor/' + id).toPromise();
  }

  public getRefName(name: string): Promise<IResponse<string>> {
    return this.api.get<string>(cryptoUserApiUrl + '/getrefname/' + name).toPromise();
  }

  public upload(file: any): Promise<IResponse<any>> {
    return this.api.uploadAvatar<any>(cryptoUserApiUrl, file).toPromise();
  }

  public updatePersonalData(data: any): Promise<IResponse<any>> {
    return this.api.post<any[]>(cryptoUserApiUrl, data).toPromise();
  }

  public changePassword(oldPass: string, newPass: string, confirmPass: string): Promise<IResponse<boolean>> {
    var data = {
      oldPassword: oldPass,
      newPassword: newPass,
      newPasswordConfirm: confirmPass
    };

    return this.api.post<boolean>(cryptoUserApiUrl + '/changepassword', data).toPromise();
  }

  public sendTwoFactor(type: CodeType = CodeType.ConfirmTwoFactor): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(cryptoUserApiUrl + '/sendTwoFactor/' + type).toPromise();
  }

  public generateNewKey(): any {
    return this.api.get<any>(cryptoUserApiUrl + '/generateauthkey').toPromise();
  }

  public confirmConnectWithKey(confirmStr: string): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(cryptoUserApiUrl + '/confirmconnectwithkey/' + confirmStr).toPromise();
  }

  public sendDeactivatedCode(): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(cryptoUserApiUrl + '/senddeactivatedcode').toPromise();
  }

  public deactivate2fa(deactive: any): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(cryptoUserApiUrl + '/deactive/' + deactive).toPromise();
  }

  public confirmCode(confirmStr: string, type: number): any {
    return this.api.get<boolean>(cryptoUserApiUrl + '/confirmcode/' + confirmStr + '/' + type).toPromise();
  }

  public sendTwoFactorWithdraw(withdrawInfoModel: WithdrawInfoModel = null): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(cryptoUserApiUrl + '/sendTwoFactor/withdraw', withdrawInfoModel).toPromise();
  }

  public getRegisteredUsersCount(): Promise<IResponse<number>> {
    return this.api.get<number>(cryptoUserApiUrl + '/getRegisteredUsersCount').toPromise();
  }

  public getUserRefBalance(): Promise<IResponse<PartnerBlockModel>> {
    return this.api.get<PartnerBlockModel>(cryptoUserApiUrl + '/getUserRefBalance/').toPromise();
  }

  public getUserRefBalanceById(userId : number): Promise<IResponse<PartnerBlockModel>> {
    return this.api.get<PartnerBlockModel>(cryptoUserApiUrl + '/getUserRefBalanceById/' + userId).toPromise();
  }

  public refLinkFollow(refValue: string): Promise<any> {
    return this.api.get(cryptoUserApiUrl + '/refLinkFollow/' + refValue).toPromise();
  }

  public getRefLinkClicksCount(): Promise<IResponse<number>> {
    return this.api.get<number>(cryptoUserApiUrl + '/getRefLinkClicksCount').toPromise();
  }

  public setHideMainBanner(): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(cryptoUserApiUrl + '/setHideMainBanner').toPromise();
  }

  public getUsersKYCStats(): Promise<IResponse<UsersKYCStatsModel>> {
    return this.api.get<UsersKYCStatsModel>(cryptoUserApiUrl + '/getUsersKYCStats').toPromise();
  }
  
  public setAutoCompoundEnabled(enable: boolean): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(cryptoUserApiUrl + '/setAutoCompoundEnabled/' + enable).toPromise();
  }
}