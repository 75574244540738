export enum PersonalDataRequestStatus {
  /// <summary>
  /// Заявка на рассмотрении
  /// </summary>
  Pending = 0,

  /// <summary>
  /// Заявка отозвана
  /// </summary>
  Released = 1,

  /// <summary>
  /// Отклонена
  /// </summary>
  Rejected = 2,

  /// <summary>
  /// Повторная заявка на рассмотрение
  /// </summary>
  PendingSecond = 3,

  /// <summary>
  /// Заявка на изменение персональных данных
  /// </summary>
  DataChanging = 4,

  /// <summary>
  /// Одобрена
  /// </summary>
  Approved = 5,

  /// <summary>
  /// Инициализирован в SumSub, но ещё не имеет верификации
  /// </summary>
  Init = 6
}