import { Injectable, OnDestroy } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApiService } from "./api.service";
import { EventModel } from "../model/event.model";
import { interval, Subscription } from "rxjs";
import { TwitterEventType } from "../constants/twitter-eventType";
import { EventApiType } from "../enums/eventApiType";

const twitterEventApiUrl = 'api/event';

@Injectable({
    providedIn: 'root'
})

export class TwitterAnalyticsService implements OnDestroy {
    environment = environment;
    private sendTwitterEventsSubscription: Subscription;
    private failedAttempts: Map<number, number> = new Map();
    private readonly maxFailedAttemps = 5;

    constructor(
        private api: ApiService,
    ) {
        this.startTwitterEventsSubscription();
    }

    private startTwitterEventsSubscription(): void {
        this.sendTwitterEventsSubscription = interval(1000 * 60 * 5).subscribe(() => {
            this.getTwitterEventsById();
        });
    }

    ngOnDestroy(): void {
        this.sendTwitterEventsSubscription?.unsubscribe();
    }

    trackEventRegistration(email_address: string, phone_number: string = null) {
        if (this.environment.enableTwitterAnalytics) {
            (<any>window).twq('event', TwitterEventType.Registration, {
                email_address: email_address, // use this to pass a user’s email address
                phone_number: phone_number // phone number in E164 standard
            });
        }
    }

    trackEventDeposit(value: number, currency: string, email_address: string, phone_number: string = null) {
        if (this.environment.enableTwitterAnalytics) {
            (<any>window).twq('event', TwitterEventType.Deposit, {
                value: value, // use this to pass the value of the conversion (e.g. 5.00)
                currency: currency, // use this to pass the currency of the conversion with an ISO 4217 code (e.g. ‘USD’)
                email_address: email_address, // use this to pass a user’s email address
                phone_number: phone_number // phone number in E164 standard
            });
        }
    }

    trackEventKYC() {
        if (this.environment.enableTwitterAnalytics) {
            (<any>window).twq('event', TwitterEventType.KYC, {
            });
        }
    }

    trackEventSuccessBuy(value: number, currency: string, email_address: string, phone_number: string = null) {
        if (this.environment.enableTwitterAnalytics) {
            (<any>window).twq('event', TwitterEventType.SuccessBuy, {
                value: value, // use this to pass the value of the conversion (e.g. 5.00)
                currency: currency, // use this to pass the currency of the conversion with an ISO 4217 code (e.g. ‘USD’)
                email_address: email_address, // use this to pass a user’s email address
                phone_number: phone_number // phone number in E164 standard
            });
        }
    }

    public getTwitterEventsById() {
        let t = this;
        const events = t.api.get<EventModel[]>(twitterEventApiUrl + '/getPendingEvents/' + EventApiType.Twitter).toPromise();

        let lastMarkedId: number | null = null;

        events.then(resp => {

            if (!resp?.data?.length) return;

            for (const event of resp.data) {
                try {
                    const params = JSON.parse(event.RequestParams);
                    t.trackEventDeposit(params.value, params.currency, params.email_address, params.phone_number);
                    t.failedAttempts.delete(event.Id); 
                    lastMarkedId = event.Id;
                } catch (error) {
                    let attempts = t.failedAttempts.get(event.Id) || 0;
                    attempts++;

                    if (attempts >= t.maxFailedAttemps) {
                        console.warn(`Event ${event.Id} failed to be sent 5 times, stopping sending attempts.`);
                        lastMarkedId = event.Id;
                        t.failedAttempts.delete(event.Id); 
                    } else {
                        console.error(`An error occurred while processing the event ${event.Id}:`, error);
                        t.failedAttempts.set(event.Id, attempts);
                        break;
                    }
                }
            }
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            if (lastMarkedId !== null) {
                const marked = new EventModel();
                marked.Id = lastMarkedId;
                marked.ApiType = EventApiType.Twitter;
                t.api.put(twitterEventApiUrl + '/markEvents/', marked).toPromise();
            }
        })
    }
}